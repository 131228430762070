.mainGrid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  margin: 10px;
  transition: 1s;
}

.mainGridGear {
  grid-template-columns: 1fr;
}

.LoginGrid {
  display: grid;
  width: 100vw;
  justify-content: center;
  margin-top: 20vh;
}

@media screen and (max-width: 1000px) {
  .mainGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

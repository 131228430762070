.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: center;
  gap: 20px;
  padding: 10px;
}

.buttonsLeft {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
